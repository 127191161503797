import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import queryString from 'query-string';
import Player from '@vimeo/player';
import { useOnScreen } from '@hooks/useOnScreen';
import { StyledIframe, Wrapper, StyledCloudImage } from './VimeoVideo.styles';
import { addError } from '@utils/logger';
import { DexecureOptimizationMode } from '@utils/cloudAssetUrl.util';

const ASPECT_RATIO = 16 / 9;

interface VimeoVideoProps {
  autoPause?: boolean;
  controls?: boolean;
  playsInline?: boolean;
  embedUrl: string;
  autoPlay?: boolean;
  background?: boolean;
  muted?: boolean;
  loop?: boolean;
  isEagerLoading?: boolean;
  cover?: boolean;
  posterUrl?: string;
  posterOptimization?: DexecureOptimizationMode;
  title: string;
  aspectRatio?: number;
  alt: string;
}

export const VimeoVideo: React.FC<VimeoVideoProps> = ({
  autoPause = true,
  controls = true,
  playsInline = true,
  embedUrl,
  autoPlay,
  background,
  muted,
  loop,
  isEagerLoading,
  cover,
  posterUrl,
  posterOptimization,
  title,
  aspectRatio = ASPECT_RATIO,
  alt = '',
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen(containerRef, '0px', true);

  const [isContainerGreaterThan16by9, setIsContainerGreaterThan16by9] = useState(false);
  const [hasError, setHasError] = useState(false);

  useLayoutEffect(() => {
    const measure = () => {
      const width = containerRef.current?.offsetWidth || 0;
      const height = containerRef.current?.offsetHeight || 0;

      setIsContainerGreaterThan16by9(width / height > aspectRatio);
    };

    measure();

    window.addEventListener('resize', measure);

    return () => {
      window.removeEventListener('resize', measure);
    };
  }, [aspectRatio]);

  const src = useMemo(() => {
    const parsedUrl = queryString.parseUrl(embedUrl);
    const params = {
      ...parsedUrl.query,
      autopause: autoPause ? 1 : 0,
      autoplay: background ? 1 : autoPlay ? 1 : 0,
      background: background ? 1 : 0,
      muted: background ? 1 : muted ? 1 : 0,
      controls: background ? 0 : controls ? 1 : 0,
      playsinline: playsInline ? 1 : 0,
      loop: loop ? 1 : 0,
    };

    return queryString.stringifyUrl({ ...parsedUrl, query: params });
  }, [embedUrl, autoPause, autoPlay, background, muted, controls, playsInline, loop]);

  const playerId = useMemo(() => `vimeo-${src}`, [src]);

  const isVideoReady = useMemo(() => isEagerLoading || onScreen, [isEagerLoading, onScreen]);
  const showVideo = useMemo(() => !hasError && isVideoReady, [hasError, isVideoReady]);

  useEffect(() => {
    if (showVideo) {
      const player = new Player(playerId);

      player
        .ready()
        .then(() => {
          setHasError(false);
        })
        .catch((error: Error) => {
          setHasError(true);
          addError(error, {
            embedUrl,
            title,
            type: 'MediaError',
          });
        });
    }
  }, [showVideo, playerId, embedUrl, title]);

  return (
    <Wrapper ref={containerRef}>
      {posterUrl && (
        <StyledCloudImage
          height="100%"
          width="100%"
          src={posterUrl}
          optimization={posterOptimization}
          isEagerLoading={isEagerLoading}
          alt={alt}
        />
      )}
      {showVideo && (
        <StyledIframe
          id={playerId}
          data-vimeo-url={src}
          ref={iframeRef}
          isContainerGreaterThan16by9={isContainerGreaterThan16by9}
          cover={cover}
          src={src}
          frameBorder="0"
          allow="autoplay; fullscreen;"
          allowFullScreen
          width="100%"
          height="100%"
          title={title}
        />
      )}
    </Wrapper>
  );
};
