import React, { useMemo } from 'react';

import { JoykitColor, TagName } from '@components/themes';
import { findSpacingVariant, Text, TextBlockVariant } from '@components/typography';
import { JoykitIcon, JoykitIconSvg, JoykitIconVariant } from '@components/JoykitIcon';
import { JoykitIconSvgVariant } from '@components/JoykitIcon/JoykitIconsSvgSet';
import { Flex } from '@components/Flex';
import { BoxProps } from '@components/Box';
import { JoykitIconSize } from '@components/themes/createTheme.util';

const alignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

type Alignment = 'left' | 'right' | 'center';

interface BaseTextPairingProps {
  id?: string;
  title: string | React.ReactElement[] | React.ReactNode; // allow html parser values
  titleTagName?: TagName;
  titleColor?: JoykitColor;
  titlePaddingLeft?: string;
  titlePaddingRight?: string;
  // TODO: make it possible to accept array of values to adjust based on screen size
  titleMaxWidth?: string;
  body?: string | React.ReactElement[] | React.ReactNode; // allow html parser values
  bodyTagName?: TagName;
  bodyColor?: JoykitColor;
  bodyPaddingLeft?: string;
  bodyPaddingRight?: string;
  bodyMaxWidth?: string;
  eyebrow?: string | React.ReactElement[]; // allow html parser values;
  eyebrowTagName?: TagName;
  eyebrowColor?: JoykitColor;
  eyebrowPaddingLeft?: string;
  eyebrowPaddingRight?: string;
  eyebrowMaxWidth?: string;
  icon?: JoykitIconVariant;
  svgIcon?: JoykitIconSvgVariant;
  iconColor?: JoykitColor;
  iconSize?: JoykitIconSize | JoykitIconSize[];
  // alignment for entire text group, default left
  alignment?: Alignment | Alignment[];
  // alignment for text inside text group, default left
  textAlign?: Alignment | Alignment[];
  style?: React.CSSProperties;
  /**
   * `spacing` is intentionally set to never, so it is not customized based on certain place in figma but based on fonts variants, to make
   * it systematic across whole site, please check if spacing variant already exist in `typographyMapping.tsx` file(there should be
   * only one variant of spacing for each fonts set);
   * if not please align it with designers first and add new variant if needed
   * (more details on `Common Styles Pairs` board here: https://www.figma.com/file/ihu7ErDxnCAKet42fEPrTa/JoyKit-Marcom-Website)
   */
  spacing?: never;
  eyebrowSpacing?: never;
  titleSpacing?: never;
}
export type TextPairingProps = TextBlockVariant & BaseTextPairingProps & Omit<BoxProps, 'color'>;

export const TextPairing: React.FC<TextPairingProps> = ({
  id,
  title,
  titleVariant,
  titleTagName,
  titleColor,
  titlePaddingLeft,
  titlePaddingRight,
  titleMaxWidth,
  body,
  bodyVariant,
  bodyTagName,
  bodyColor,
  bodyPaddingLeft,
  bodyPaddingRight,
  bodyMaxWidth,
  eyebrow,
  eyebrowVariant,
  eyebrowTagName,
  eyebrowColor,
  eyebrowPaddingLeft,
  eyebrowPaddingRight,
  eyebrowMaxWidth,
  icon,
  svgIcon,
  iconColor,
  iconSize,
  alignment,
  textAlign,
  children,
  ...restProps
}) => {
  // Automated spacing mapping according to https://www.figma.com/file/ihu7ErDxnCAKet42fEPrTa/JoyKit-Marcom-Website
  const spacingVariant = useMemo(() => findSpacingVariant({ eyebrowVariant, titleVariant, bodyVariant }), [
    eyebrowVariant,
    titleVariant,
    bodyVariant,
  ]);
  if (alignment && !textAlign) {
    textAlign = alignment;
  }
  const flexAlignment = useMemo(() => {
    const arrAlignment = Array.isArray(alignment) ? alignment : [alignment];
    return arrAlignment.map(alignmentItem => alignmentItem && alignmentMap[alignmentItem]);
  }, [alignment]);
  return (
    <Flex id={id} {...restProps} flexDirection="column" alignItems={flexAlignment}>
      {icon && <JoykitIcon name={icon} color={iconColor || 'blue6'} size={iconSize} marginBottom={[3]} />}
      {svgIcon && <JoykitIconSvg name={svgIcon} color={iconColor || 'blue6'} size={iconSize} marginBottom={[3]} />}
      {eyebrow && eyebrowVariant && (
        <Text
          className="text-pairing-eyebrow"
          variant={eyebrowVariant}
          tagName={eyebrowTagName || 'p'}
          color={eyebrowColor}
          textAlign={textAlign}
          style={{
            paddingLeft: eyebrowPaddingLeft || undefined,
            paddingRight: eyebrowPaddingRight || undefined,
            maxWidth: eyebrowMaxWidth,
          }}
          marginBottom={spacingVariant.eyebrowSpacing}
        >
          {eyebrow}
        </Text>
      )}
      <Text
        className="text-pairing-title"
        variant={titleVariant}
        tagName={titleTagName || 'p'}
        color={titleColor}
        textAlign={textAlign}
        style={{
          paddingLeft: titlePaddingLeft || undefined,
          paddingRight: titlePaddingRight || undefined,
          maxWidth: titleMaxWidth,
        }}
        marginBottom={body ? spacingVariant.titleSpacing : 0}
      >
        {title}
      </Text>
      {body && bodyVariant && (
        <Text
          className="text-pairing-body"
          variant={bodyVariant}
          tagName={bodyTagName || 'p'}
          marginBottom={0}
          color={bodyColor}
          textAlign={textAlign}
          style={{
            paddingLeft: bodyPaddingLeft || undefined,
            paddingRight: bodyPaddingRight || undefined,
            maxWidth: bodyMaxWidth,
          }}
        >
          {body}
        </Text>
      )}
      {children}
    </Flex>
  );
};
