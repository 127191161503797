import { styled } from '@styledComponents';
import { Box } from '@components/Box';
import { CloudImage } from '@components/CloudImage';

export const StyledIframe = styled.iframe<{ cover?: boolean; isContainerGreaterThan16by9?: boolean }>`
  position: absolute;
  ${props =>
    props.cover &&
    `
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 9999px;
  height: 100%;

  ${props.isContainerGreaterThan16by9 &&
    `
      height: 9999px;
      width: 100%;
  `}

  `}
`;

export const Wrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export const StyledCloudImage = styled(CloudImage)`
  position: absolute;
  object-fit: cover;
`;
