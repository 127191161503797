import { useEffect, useState } from 'react';

export const useOnScreen = <T extends Element>(
  ref: React.RefObject<Element | null>,
  rootMargin = '0px',
  firstTimeOnly = false,
  threshold = 0
) => {
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const refElement = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If firstTimeOnly is true will track first time entered on section and not track leave from section, if false(default) then it will working as it is.
        if ((firstTimeOnly && entry.isIntersecting) || !firstTimeOnly) {
          setIntersecting(entry.isIntersecting);
        }
      },
      {
        rootMargin,
        threshold,
      }
    );
    if (refElement) {
      observer.observe(refElement);
    }
    return () => {
      if (refElement) {
        observer.unobserve(refElement);
      }
    };
  }, [ref, rootMargin, firstTimeOnly, threshold]);

  return isIntersecting;
};
